import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { rgba } from "emotion-rgba";
import React from "react";

const SpanWrapper = styled('span')`
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &&::-webkit-scrollbar {
    display: none;
  }
`;

const DateSpan = styled('span')`
  color: ${({theme}) => theme.themeColor.bodyMain};
`

const Time = styled('span')`
  color: ${({theme}) => rgba(theme.themeColor.bodyMain, 0.4)};
`

const TableDateTime = ({ value, ...props }) => {
  if (!value) return <span />;
  const theme = useTheme();

  const d = new Date(+value);
  const datetime = d.toLocaleString();

  const [date, time] = datetime.split(', ');

  return (
    <SpanWrapper {...props}>
      <DateSpan theme={theme}>{date}</DateSpan><Time> @ {time}</Time>
    </SpanWrapper>
  );
}

export default TableDateTime;