import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Container, Stack, Tab, Tabs } from '@mui/material';
import { gql, useQuery } from "@apollo/client";
import CellWrapper from '../GridInputCell/CellWrapper';
import ActionColumn from './ActionColumn';
import { UserContext } from '../../context/UserContext';
import { GET_TEAMS_BY_USER_PORTFOLIO_ID } from '../../apollo/queries/findTeamsByUserPortfolioId';
import StandardGrid from '../Grid/Grid';
import TableSkeleton from '../Skeleton/TableSkeleton';
import TableDateTime from '../TableDateTime/TableDateTime';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { capitalValueFormatter } from '../../utilities';
import LawfirmStates from './LawfirmStates';
import Modal from '../../styled/Modal/Modal';
import Typography from '../../styled/Typography/Typography';
import ManageMembersModal from './ManageMembersModal';
import MembersList from '../MembersList/MembersList';
import { JUDICIAL } from '../../utilities/genericSteps';

const Title = styled(Typography)`
  color: ${({ theme }) => theme.themeColor.bodyMain};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 25px 0 0 0;
`

export const CREATE_INVITATION = gql`
  mutation CREATE_INVITATION($input: createInvitationInput) {
    createInvitation(input: $input) {
      id
      email
    }
  }
`
const myColsDef = [
  {
    headerName: "Company Name",
    field: "name",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellRendererParams: {
      label: 'Company Name'
    }
  },
  {
    headerName: "First Name",
    field: "firstName",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellRendererParams: {
      label: 'First Name'
    }
  },
  {
    headerName: "Last Name",
    field: "lastName",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellRendererParams: {
      label: 'Last Name'
    },
  },
  {
    headerName: "Administrators",
    field: "administratorsLength",
    sortable: true,
    filter: true,
    hide: true,
  },
  {
    headerName: "Addresses",
    field: "addressesLength",
    sortable: true,
    filter: true,
    hide: true,
  },
  {
    headerName: "States",
    field: "states",
    sortable: true,
    filter: true,
  },
  {
    headerName: "Assets",
    field: "assets",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Members",
    field: "members",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Created",
    field: "dateCreated",
    sortable: true,
    filter: true,
    cellRenderer: "TableDateTime",
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Website",
    field: "website",
    sortable: true,
    filter: true,
    hide: true,
  },
  {
    headerName: "Invite Status",
    field: "inviteStatus",
    sortable: true,
    filter: true,
    filterParams: {
      valueFormatter: capitalValueFormatter
    },
    cellRenderer: "ActionColumn",
    cellClass: "ag-show-dropdown-modal",
    suppressColumnsToolPanel: true
  },
];

const frameworkComponents = {
  ActionColumn,
  CellWrapper,
  TableDateTime,
  LawfirmStates
}

const TableContainer = styled(Box)`
  margin: 0 auto;
  height: 98%;
  width: 98%;
  border: ${({ theme }) => `1px solid ${theme.themeColor.sectionStroke}`};
  border-radius: ${({ theme }) => theme.themeSizing.borderRadiusLarge};
`

const ls = window.localStorage;

const PINNED_ROW_DATA = [{
  name: '',
  email: '',
  firstName: '',
  lastName: '',
  states: '',
  inviteStatus: 'fr-inviteStatus',
  assets: '',
  dateCreated: '',
  members: '',
}]

export default function LawfirmsList() {
  const { user } = useContext(UserContext);
  const gridApiRef = useRef(null);
  const theme = useTheme();

  const [columnDefs, setColumnDefs] = useState(myColsDef);
  const [teams, setTeams] = useState([]);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [showManageTeamModal, setShowManageTeamModal] = useState(false);
  const [screen, setScreen] = useState(0);

  function onGridReady(params) {
    gridApiRef.current = params.api;
  }

  function onGridSizeChanged() {
    gridApiRef.current.sizeColumnsToFit();
  }

  const { loading, data } = useQuery(GET_TEAMS_BY_USER_PORTFOLIO_ID);

  useEffect(() => {
    if (data) {
      const teams = [];

      [...data.findTeamsByUserPortfolioId].forEach((team) => {
        if (team.type !== 'LAWFIRM') return;
        const teamCopy = { ...team };

        const teamPortfolioUsers = teamCopy.portfolio?.users.length
          ? teamCopy.portfolio.users.map((user) => user.id)
          : [];

        const members = team.memberships.filter((member) => {
          return member.user.id != user?.userID && !member.deleted && !teamPortfolioUsers.includes(member.user.id);
        })
        teamCopy.memberships = members;
        teams.push(teamCopy);
      })
      const transformedTeams = transformTeamData(teams);
      setTeams(transformedTeams);
    }
    if (gridApiRef?.current)
      setTimeout(() => gridApiRef.current.sizeColumnsToFit(), 0);
  }, [data]);

  useEffect(() => {
    const visibleColumns = ls.getItem('lawfirmsList') ? JSON.parse(ls.getItem('lawfirmsList')) : [];
    const newCols = [...columnDefs].map((col) => {
      const found = visibleColumns.find((vc) => vc.colId === col.field);
      if (found && found.colId != 'inviteStatus') {
        col.hide = found.hide;
      } 
      return col
    });
    if (newCols) setColumnDefs(newCols);
  }, []);

  const gridOptions = {
    suppressPropertyNamesCheck: true,
    pagination: true,
    rowSelection: 'single',
    columnDefs,
    pinnedTopRowData: PINNED_ROW_DATA,
  };

  if (loading) return (
    <Container sx={{ height: '100%' }}>
      <TableSkeleton columnDefs={myColsDef} />
    </Container>
  );

  function transformTeamData(teams) {
    const transformedTeams = teams.map((item) => {
      const firstInvitation = [...item.invitations].sort((a, b) => a.id - b.id)[0];
      const stateContacts = {};

      item?.contacts?.length && item.contacts.forEach((item) => {
        stateContacts[item.state] = {
          defaultEmail: item.defaultEmail,
          nonDefaultEmail: item.nonDefaultEmail,
          checked: item.nonDefaultEmail && item.nonDefaultEmail !== ''
        }
      })

      return {
        id: item.id,
        name: item.name,
        assets: item.assets.length || 0,
        dateCreated: new Date(+item.createdAt),
        states: item.states,
        inviteStatus: firstInvitation ? firstInvitation.status : "",
        members: item.memberships.length || 0,
        memberships: item.memberships,
        type: item.type,
        firstName: firstInvitation ? firstInvitation.firstName : "",
        lastName: firstInvitation ? firstInvitation.lastName : "",
        contacts: stateContacts,
        contactsData: item.contacts,
        invitationID: firstInvitation ? firstInvitation.id : null,
        website: item.website,
        administrators: item.administrators,
        administratorsLength: item.administrators.length,
        officeAddresses: item.officeAddresses,
        addressesLength: item.officeAddresses.length,
        teams: teams,
      }
    });

    return transformedTeams;
  }

  function onCellClicked(e) {
    if (e.column.colId === 'inviteStatus') return;
    if (!e.data.id) return;

    const team = gridApiRef.current.getSelectedRows()[0];

    if (team) {
      const memberships = team.memberships.map((member) => ({
        ...member, teamType: team.type
      }))

      setSelectedTeam(team);
      setSelectedTeamMembers(memberships);
      setOpenDetailModal(true);
    }
  }

  const handleChange = (event, newValue) => {
    setScreen(newValue);
  };

  return (
    <TableContainer sx={{ height: '100%', border: `1px solid ${theme.themeColor.sectionStroke}` }}>
      <StandardGrid
        tableName="lawfirmsList"
        onGridReady={onGridReady}
        rowData={teams}
        gridOptions={gridOptions}
        onGridSizeChanged={onGridSizeChanged}
        components={frameworkComponents}
        onCellClicked={onCellClicked}
      />

      <ManageMembersModal
        showModal={showManageTeamModal}
        data={data}
        selectedTeamID={selectedTeam?.id}
        selectedTeam={selectedTeam}
        selectedTeamMembers={selectedTeamMembers}
        setSelectedTeamMembers={setSelectedTeamMembers}
        setShowModal={setShowManageTeamModal} 
      />

      <Modal
        open={openDetailModal}
        width="90%"
        height="90%"
        sx={{ display: 'flex', flexDirection: 'column' }}
        onClose={() => setOpenDetailModal(false)}
      >

        <Tabs
          theme={theme}
          value={screen}
          onChange={handleChange}
        >
          <Tab theme={theme} label="Profile" />
          <Tab theme={theme} label="Manage Members" />
        </Tabs>
        {screen === 0 && (
          <Box sx={{ mt: 2 }}>
            <Stack direction='row' spacing={10}>
              <Box>
                <Typography variant="h4" component="h2">{selectedTeam?.name}</Typography>
                <Typography><a href={`${selectedTeam?.website}`} target='_blank' rel="noreferrer">{selectedTeam?.website}</a></Typography>
              </Box>
            </Stack>
        
            <Stack spacing={1}>
              <Title>Administrators:</Title>

              {selectedTeam?.administrators.map((admin) => (
                <Typography key={admin.id}>{admin.firstName} {admin.lastName} - {admin.email}</Typography>
              ))}
            </Stack>

            <Stack spacing={1}>
              <Title>Office Addresses:</Title>
          
              {selectedTeam?.officeAddresses.map((address) => (
                <Typography key={address.id}>{address.address}, {address.city}, {address.state}, {address.county}, {address.postal}</Typography>
              ))}
            </Stack>

            <Stack spacing={1}>
              <Title>States and Jurisdiction-specific referral emails:</Title>
              <ul>
                {selectedTeam?.states.map((state) => {
                  const stateContacts = selectedTeam.contacts[state];
                  const isStateJudicial = JUDICIAL.includes(state);

                  if (isStateJudicial) {
                    return (
                      <li key={state}>
                        {state}
                        {stateContacts && stateContacts.defaultEmail && (
                          <ul>
                            <li>Judicial referral contact: <a href={`mailto:${stateContacts.defaultEmail}`}>{stateContacts.defaultEmail}</a></li>
                            {stateContacts.nonDefaultEmail && (
                              <li>Non-Judicial referral contact: <a href={`mailto:${stateContacts.nonDefaultEmail}`}>{stateContacts.nonDefaultEmail}</a></li>
                            )}
                          </ul>
                        )} 
                      </li>
                    )
                  }

                  return (
                    <li key={state}>
                      {state}
                      {stateContacts && stateContacts.nonDefaultEmail && (
                        <ul>
                          <li>Non-Judicial referral contact: <a href={`mailto:${stateContacts.nonDefaultEmail}`}>{stateContacts.nonDefaultEmail}</a></li>
                          {stateContacts.defaultEmail && (
                            <li>Judicial referral contact: <a href={`mailto:${stateContacts.defaultEmail}`}>{stateContacts.defaultEmail}</a></li>
                          )}
                        </ul>
                      )} 
                    </li>
                  )
                })}
              </ul>
            </Stack>
          </Box>
        )}
        {screen === 1 && (
          <MembersList teamID={selectedTeam?.id} selectedTeam={selectedTeam} selectedTeamMembers={selectedTeamMembers} setSelectedTeamMembers={setSelectedTeamMembers} />
        )}
      </Modal>
    </TableContainer>
  )
}