import { useQuery } from "@apollo/client";
import { useTheme } from "@emotion/react";
import { CircularProgress, Container, Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { GET_TASK_FLOW_TEMPLATES_QUERY } from "../../apollo/queries/getTaskFlowTemplatesQuery";
import Modal from "../../styled/Modal/Modal";
import Typography from "../../styled/Typography/Typography";
import Button from "../../styled/Button/Button";
import CreateTaskFlow from "../TaskFlow/CreateTaskFlow";
import StandardGrid from "../Grid/Grid";
import TemplateSettings from "./TemplateSettings";

const columnDefs = [
  {
    headerName: "Category",
    field: "subcategory",
    sortable: true,
    sort: 'asc',    
    hide: true,
    rowGroup: true,
    width: 400,
  },
  {
    headerName: "Name",
    field: "name",
    sortable: true,
    filter: true,
    rowGroup: true,
    hide: true,
    groupUseEntireRow: true,
    width: 400,
  },
  {
    headerName: "Subtasks",
    field: "subtasks",
    filter: true,
    minWidth: 400,
    width: 550
  },
  {
    headerName: "",
    field: "",
    sortable: true,
    width: 300,
    cellRenderer: "TemplateSettings",
  },
  {
    headerName: "",
    field: "noSettings",
    sortable: true,
    filter: true,
  },
];

const components = {
  TemplateSettings,
}

const gridOptions = {
  suppressPropertyNamesCheck: true,
  columnDefs,
  rowSelection: "single",
  groupDisplayType: 'multipleColumns',
  animateRows: true,
  groupHideOpenParents: true,
  getRowStyle: (params) => {
    if (params.node.key === "Borrower Breaks Payment Plan (In REM Relief)") {
      return ({ fontStyle: 'italic' })
    }
  }
};

const TaskFlowList = ({ show, onClose, portfolioID, onClickCreate, processID, assetID, milestoneID, activity }) => {
  const [flowTemplates, setFlowTemplates] = useState([]);
  const [showCreateTaskFlow, setShowCreateTaskFlow] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(false);

  const theme = useTheme();
  const gridApiRef = useRef(null);

  const { loading, data } = useQuery(GET_TASK_FLOW_TEMPLATES_QUERY, {
    variables: { portfolioID }
  });

  const onSelectFlow = (flow) => {
    setSelectedFlow(flow);
    setShowCreateTaskFlow(true);
  }

  const transformTemplateData = (data) => {
    const nonDeleted = data.findTaskFlowTemplate.filter((taskFlow) => !taskFlow.deleted)
    const result = [];

    const generateCategories = (templates) => {
      const categories = {
        'FORECLOSURE': [],
        'BANKRUPTCY': [],
        'ATTORNEY': [],
        'LOSS_MITIGATION': [],
        'EXPENSE/FEES/ADVANCE': [],
        'OTHER': [],
      }
      templates.map((taskFlow) => {      
        if (!taskFlow.processType) {
          categories['OTHER'].push(taskFlow);
        }
        if (taskFlow.processType && taskFlow.processType.includes('OTHER')) {
          categories['OTHER'].push(taskFlow)
        }
        if (taskFlow.processType && taskFlow.processType.includes('ATTORNEY')) {
          categories['ATTORNEY'].push(taskFlow)
        }
        if (taskFlow.processType && taskFlow.processType.includes('EXPENSE/FEES/ADVANCE')) {
          categories['EXPENSE/FEES/ADVANCE'].push(taskFlow)
        }
        if (taskFlow.processType && taskFlow.processType.includes('FORECLOSURE')) {
          categories['FORECLOSURE'].push(taskFlow)
        } 
        if (taskFlow.processType && taskFlow.processType.includes('BANKRUPTCY')) {
          categories['BANKRUPTCY'].push(taskFlow)
        } 
        if (taskFlow.processType && (taskFlow.processType.includes('LOSS_MITIGATION') || taskFlow.processType.includes('LOSS MITIGATION'))) {
          categories['LOSS_MITIGATION'].push(taskFlow)
        }
      });
      return categories;
    };

    const templates = generateCategories(nonDeleted);

    const processData = (flow, process) => {
      flow.subtasks.forEach((subtask, i) => {
        result.push({
          name: flow.name,
          description: flow.description,
          subtasks: subtask.name,
          createOnMilestone: flow.createOnMilestone,
          createOnMilestoneID: flow.createOnMilestoneID,
          createdBy: flow.createdBy,
          id: flow.id,
          states: flow.createOnMilestone ? flow.states : null,
          allSubtasks: flow.subtasks,
          subcategory: process,
          showButtons: flow.subtasks[i - 1] ? false : true,
          onSelectFlow: onSelectFlow
        });
      })
    }

    for (const [process, flows] of Object.entries(templates)) {
      flows.forEach((flow) => {
        processData(flow, process);
      });
    }

    return result.sort((a, b) => a.name.localeCompare(b.name));
  }
  
  useEffect(() => {
    if (data) {
      setFlowTemplates(transformTemplateData(data));
    }
  }, [data]);

  if (loading) return <CircularProgress />;

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  }
  const onRowClicked = (e) => {
    if (!e?.data) return;
    const copy = { ...e.data, subtasks: e.data.allSubtasks }
    onSelectFlow(copy);
  }
  const onGridSizeChanged = () => gridApiRef.current.sizeColumnsToFit();
  const autoGroupColumnDef = { minWidth: 300, cellRendererParams: { suppressCount: true } }

  const onCloseCreateTaskFlow = () => {
    setShowCreateTaskFlow(false);
    onClose();
  }

  return (
    <>
      <Modal open={show} onClose={onClose}
        height={flowTemplates.length === 0 ? "auto" : "85vh"}
        width={flowTemplates.length === 0 ? "auto" : "90vw"}
      >
        <Container sx={{
          marginBottom: '30px',
          background: theme.themeColor.backgroundBody
        }}>
          {flowTemplates.length === 0 
            ? <Typography component="h1" variant="h4" mb={3}>Please Create a Task Flow Template</Typography>
            : <Typography component="h1" variant="h4">Select Task Flow Template</Typography>
          }
        </Container>
        {flowTemplates.length === 0
          ? <Button onClick={onClickCreate}>Create Task Flow</Button>
          : (
            <Box sx={{height: '80%', width: '100%' }}>
              <StandardGrid
                tableName="taskFlowTemplatesList"
                onGridReady={onGridReady}
                rowData={flowTemplates}
                onRowClicked={onRowClicked}
                gridOptions={gridOptions}
                onGridSizeChanged={onGridSizeChanged}
                autoGroupColumnDef={autoGroupColumnDef}
                components={components}
                floatingButton={
                  <Button onClick={onClickCreate}>Create Task Flow</Button>
                }
              />
            </Box>
          )
        }
      </Modal>
      <CreateTaskFlow
        show={showCreateTaskFlow}
        onClose={onCloseCreateTaskFlow}
        flowTemplate={selectedFlow}
        processID={processID}
        assetID={assetID}
        milestoneID={milestoneID}
        activity={activity}
      />
    </>
  )
}

export default TaskFlowList;