import { gql } from "@apollo/client";

export const GET_TEAMS_BY_USER_PORTFOLIO_ID = gql`
  query Query {
    findTeamsByUserPortfolioId {
      id
      name
      type
      createdAt
      states
      website
      contacts {
        defaultEmail
        nonDefaultEmail
        state
      }
      officeAddresses
      administrators
      website

      memberships {
        id
        deleted
        user {
          id
          blocked
          email
          firstName
          lastName
          memberStatus
        }
        type
      }

      invitations {
        id
        email
        status
        firstName
        lastName
      }

      createdBy {
        id
        firstName
        lastName
      }

      portfolios {
        id
        name
        assets {
          id
        }
        users {
          id
          email
        }
      }

      assets {
        id
      }
    }
  }
`;