import React, { useState, useRef, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { DOCUMENTS_BY_ASSET_QUERY } from "../../apollo/queries/documentsByAssetQuery";
import { DOCUMENT_DOWNLOAD_LINK } from "../../apollo/queries/downloadLink"
import StandardGrid from "../Grid/Grid";
import TableSkeleton from "../Skeleton/TableSkeleton";
import DocumentDescription from "./DocumentDescription";
import DocumentExtension from "./DocumentExtension";
import DocumentCreator from "./DocumentCreator";
import TableDateTime from "../TableDateTime/TableDateTime";
import DocumentUpload from "../Modals/DocumentUpload";
import Button from "../../styled/Button/Button";
import DocumentSettings from "./DocumentSettings";
import { isForeclosure, isBankruptcy } from "../../utilities";
import Typography from "../../styled/Typography/Typography";
import styled from '@emotion/styled';

const CellText = styled(Typography)`
  font-size: 12px;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &&::-webkit-scrollbar {
    display: none;
  }
} 
`

const colDefs = [
  {
    headerName: "Type",
    field: "documentType",
    sortable: true,
  },
  {
    headerName: "Name",
    field: "name",
    sortable: true,
    cellRenderer: (params) => 
      <CellText>{params.value}</CellText>
  },
  {
    headerName: "Dsc",
    field: "description",
    sortable: false,
    cellRenderer: DocumentDescription,
    hide: false
  },
  {
    headerName: "Ext",
    field: "extension",
    sortable: true,
    cellRenderer: DocumentExtension,
    hide: true
  },
  {
    headerName: "By",
    field: "uploadedBy",
    sortable: true,
    cellRenderer: DocumentCreator,
  },
  {
    headerName: "Process Type",
    field: "process",
    sortable: true,
    cellRenderer: (params) => 
      <CellText>{params.value}</CellText>
  },
  {
    headerName: "Uploaded",
    field: "uploadedAt",
    sortable: true,
    cellRenderer: TableDateTime,
  },
  {
    headerName: "",
    field: "settings",
    sortable: false,
    cellRenderer: DocumentSettings,
    suppressColumnsToolPanel: true,
  }
];

const frameworkComponents = {
  DocumentDescription,
  DocumentExtension,
  DocumentCreator,
  TableDateTime,
  DocumentSettings
};

const ls = window.localStorage;
const POLL_INTERVAL = parseInt(process.env.REACT_APP_BIG_POLL_INTERVAL);

export default function DocumentTable({ asset, processID, readOnly, activity, originalFC }) {
  const gridApiRef = useRef(null);
  const [showUpload, setShowUpload] = useState(false);
  const [columnDefs, setColumnDefs] = useState(colDefs);
  const availableProcesses = new Set(["activity"]);
  asset.processes.forEach((process) => {
    if (isForeclosure(process)) availableProcesses.add("foreclosure");
    if (isBankruptcy(process)) availableProcesses.add("bankruptcy");
  })

  const gridOptions = {
    suppressPropertyNamesCheck: true,
    pagination: true,
    rowSelection: "single",
    columnDefs,
  };

  function onGridReady(params) {
    gridApiRef.current = params.api;
  }

  function onGridSizeChanged() {
    gridApiRef.current.sizeColumnsToFit();
  }

  const {
    loading: loadingDocuments,
    data: dataDocuments,
  } = useQuery(DOCUMENTS_BY_ASSET_QUERY, {
    variables: {
      assetID: asset.id
    },
    pollInterval: POLL_INTERVAL
  });

  const [
    getDownloadLink
  ] = useLazyQuery(DOCUMENT_DOWNLOAD_LINK);

  useEffect(() => {
    const visibleColumns = ls.getItem('membersList') ? JSON.parse(ls.getItem('membersList')) : [];
    const newCols = [...columnDefs].map((col) => {
      const found = visibleColumns.find((vc) => vc.colId === col.field);
      if (found) col.hide = found.hide;
      return col;
    });
    if (newCols) setColumnDefs(newCols);
  }, [])

  function onCellClicked(e) {
    if (e.column.colId === 'settings' || e.column.colId === 'description') return;
    
    const document = gridApiRef.current.getSelectedRows()[0];
    if (document) {
      getDownloadLink({ variables: {id: document.id}}).then(result => {
        const win = window.open(result.data.downloadDocumentLink.downloadLink, "_blank");
        win.focus();
      })
    }
  }

  function transformDocumentData(documents) {
    if (!documents) return [];
    return documents.map((item) => ({
      id: item.id,
      name: item.name,
      documentType: item.documentType,
      description: item.description,
      uploadedBy: `${item.uploadedBy.firstName} ${item.uploadedBy.lastName}`,
      uploadedAt: new Date(+item.uploadedAt),
      extension: item.extension,
      assetID: asset.id,
      process: item.process.charAt(0).toUpperCase() + item.process.slice(1),
      processID: processID,
    }));
  }

  if (loadingDocuments) return (
    <TableSkeleton columnDefs={columnDefs} />
  );

  const UploadButton = <Button onClick={() => setShowUpload(true)}>Upload</Button>

  return (
    <>
      <DocumentUpload
        open={showUpload}
        assetID={asset.id}
        processID={processID}
        onClose={() => setShowUpload(false)}
        availableProcesses={[...availableProcesses]}
        activity={activity}
        originalFC={originalFC}
      />
      <StandardGrid
        tableName="documentTable"
        onGridReady={onGridReady}
        rowData={transformDocumentData(dataDocuments?.findDocuments.filter(doc => !doc.deleted))}
        onCellClicked={onCellClicked}
        gridOptions={gridOptions}
        onGridSizeChanged={onGridSizeChanged}
        components={frameworkComponents}
        floatingButton={!readOnly && UploadButton}
      />
    </>
  );
}