import React from 'react';
import { TimelineContent } from "@mui/lab";
import { Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import { getInitials, calculateHoldRange } from "../../helpers";
import Typography from '../../styled/Typography/Typography';
import CommentIcon from '../CommentIcon/CommentIcon';
import ChatBubble from '@mui/icons-material/ChatBubble';
import MentionHighlighter from '../Mentions/MentionHighlighter';

const Bold = styled(Typography)`
  font-weight: 800;
  color: ${({ theme }) => theme.themeColor.bodyMain};
`;
const TimelineItem = styled(TimelineContent)`
  color: ${({ theme }) => theme.themeColor.bodySecondary};
  display: flex;
  justify-content: space-between;
`;
const BigInitials = styled('div')`
  border: ${(props) => `1px solid ${props.theme.themeColor.brandPrimaryBlue}`};
  color: ${(props) => props.theme ? props.theme.themeColor.userIcon.text : "blue"};
  font-size: 16px;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;
const NumbersCircle = styled('div')`
  border: ${(props) => `1px solid ${props.theme.themeColor.bodyMain}`};
  color: ${(props) => props.theme.themeColor.bodyMain};
  font-size: 16px;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;
const SmallInitials = styled('div')`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: flex;
  background: ${(props) => {
  if (props.userType === "PORTFOLIO_MANAGER") return props.theme.themeColor.userIcon.background;
  else return props.theme.themeColor.sectionStroke;
}};
  color: ${(props) => {
  if (props.userType === "PORTFOLIO_MANAGER") return props.theme.themeColor.userIcon.text;
  else return;
}};
  font-size: 12px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-right: 4px;
`;
const SameLine = styled('div')`
  display: flex;
  justify-content: space-between;
`;
const Box = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 90%;
`;
const CommentTitle = styled("div")`
  color: ${(props) => props.theme.themeColor.bodySecondary};
  font-weight: 500;
`;
const CommentText = styled("div")`
  line-height: 18px;
`;
const ChatDot = styled("div")`
  position: absolute;
  width: 7px;
  height: 7px;
  top: 9px;
  left: 135px;

  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.themeColor.brandPrimaryGreen};
`;

const commentTemplate = (event, theme) => {
  return (
    <TimelineContent
      theme={theme}
      sx={{ paddingTop: 0, color: theme.themeColor.bodySecondary, marginTop: '6px' }}
    >
      <SameLine>
        <Typography sx={{width: '98%'}}>
          <MentionHighlighter>{event.description}</MentionHighlighter>
        </Typography>
        <Tooltip title={event.createdBy} placement="top">
          <SmallInitials
            theme={theme}
            userType={event.createdByType}
            children={<span>{getInitials(event.createdBy)}</span>}
          />
        </Tooltip>
      </SameLine>
    </TimelineContent>
  );
};

const holdCreatedTemplate = (event, theme, handleOpenHoldComments) => {
  const { hold } = event;
  let totalComments = 0;
  if (hold) totalComments = hold.hold_comments.length;

  const holdRange = calculateHoldRange(
    new Date(+event.originalCreatedDate).setHours(0, 0, 0, 0),
    new Date().setHours(0, 0, 0, 0)
  );
  const holdFinalDate = new Date(+event.hold?.expectedCloseDate).toLocaleString(
    undefined,
    {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }
  );

  return (
    <TimelineItem theme={theme} sx={{ minHeight: "90px" }}>
      <Box>
        Hold Opened {holdFinalDate && `Till ${holdFinalDate}`}
        <Bold theme={theme}>{event.holdType}</Bold>
        <Typography>{event.description}</Typography>
      </Box>
      <div style={{ display: "flex", width: "125px", justifyContent: "space-between" }}>
        <SmallInitials
          theme={theme}
          children={<span>{getInitials(event.createdBy)}</span>}
          style={{ color: theme.themeColor.bodyMain }}
        />
        <Tooltip title={`${holdRange} days on hold`} placement="top">
          <NumbersCircle
            theme={theme}
            children={<Typography number>+{holdRange}</Typography>}
          />
        </Tooltip>
        {hold && (
          <CommentIcon
            value={totalComments}
            onClick={() => handleOpenHoldComments(hold)}
          />
        )}
      </div>
    </TimelineItem>
  );
};

const holdReopenedTemplate = (event, theme) => {
  const holdRange = calculateHoldRange(event.originalCreatedDate, event.originalDueBy);

  return (
    <TimelineItem theme={theme}>
      <Box>
        Hold Reopened
        <Bold theme={theme}>{event.holdType}</Bold>
      </Box>
      <Tooltip title={`${holdRange} days remaining`} placement="top">
        <NumbersCircle theme={theme} children={<span>+{holdRange}</span>} />
      </Tooltip>
    </TimelineItem>
  );
}

const holdClosedTemplate = (event, theme) => (
  <TimelineItem theme={theme}>
    <Box>
      Hold Closed
      <Bold theme={theme}>{event.holdType}</Bold>
    </Box>
    <Box style={{ display: "flex", flexDirection: "row" }}>
      <Tooltip title={event.createdBy} placement="top">
        <BigInitials
          theme={theme}
          children={<span>{getInitials(event.createdBy)}</span>}
        />
      </Tooltip>
    </Box>
  </TimelineItem>
);

const holdDueDateTemplate = (event, theme) => (
  <TimelineItem theme={theme}>
    <Box>
    Hold Expected Close Date updated from {new Date(event.description).toLocaleDateString()} to {new Date(event.dueBy).toLocaleDateString()}
      <Bold theme={theme}>{event.holdType}</Bold>
    </Box>
    <Tooltip title={event.createdBy} placement="top">
      <BigInitials
        theme={theme}
        children={<span>{getInitials(event.createdBy)}</span>}
      />
    </Tooltip>
  </TimelineItem>
)

const taskCreatedTemplate = (
  event,
  theme,
  handleOpenTaskComments,
) => {
  const { task } = event;
  let totalComments = 0;
  if (task) {
    totalComments = task.task_comments.length;
  }
  return (
    <TimelineItem theme={theme} sx={{ minHeight: "90px" }}>
      <Box>
        Task Created
        <Bold theme={theme}>{event.title}</Bold>
        <Typography>{event.description}</Typography>
      </Box>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Tooltip title={event.createdBy} placement="top">
          <BigInitials
            theme={theme}
            children={<span>{getInitials(event.createdBy)}</span>}
          />
        </Tooltip>
        {task && (
          <CommentIcon
            value={totalComments}
            onClick={() => handleOpenTaskComments(task)}
          />
        )}
      </Box>
    </TimelineItem>
  );
};

const taskReopenedTemplate = (event, theme) => (
  <TimelineItem theme={theme}>
    <Box>
      Task Reopened
      <Bold theme={theme}>{event.description}</Bold>
    </Box>
    <Tooltip title={event.createdBy} placement="top">
      <BigInitials
        theme={theme}
        children={<span>{getInitials(event.createdBy)}</span>}
      />
    </Tooltip>
  </TimelineItem>
);

const taskReassignedTemplate = (event, theme) => (
  <TimelineItem theme={theme}>
    <Box>
      {event.description}
      <Bold theme={theme}>{event.title}</Bold>
    </Box>
    <Tooltip title={event.createdBy} placement="top">
      <BigInitials
        theme={theme}
        children={<span>{getInitials(event.createdBy)}</span>}
      />
    </Tooltip>
  </TimelineItem>
);

const taskDueDateTemplate = (event, theme) => (
  <TimelineItem theme={theme}>
    <Box>
      Task Due Date Updated to {event.dueBy}
      <Bold theme={theme}>{event.description}</Bold>
    </Box>
    <Tooltip title={event.createdBy} placement="top">
      <BigInitials
        theme={theme}
        children={<span>{getInitials(event.createdBy)}</span>}
      />
    </Tooltip>
  </TimelineItem>
);

const taskMilestoneTemplate = (event, theme) => {
  return (
    <TimelineItem theme={theme}>
      <Box>
        {event.description}
        <Bold theme={theme}>{event.title}</Bold>
      </Box>
      <Tooltip title={event.createdBy} placement="top">
        <BigInitials
          theme={theme}
          children={<span>{getInitials(event.createdBy)}</span>}
        />
      </Tooltip>
    </TimelineItem>
  )
};

const taskCompleteTemplate = (event, theme) => (
  <TimelineItem theme={theme}>
    <Box>
      Task Complete
      <Bold theme={theme}>{event.description}</Bold>
    </Box>
    <Tooltip title={event.createdBy} placement="top">
      <BigInitials
        theme={theme}
        children={<span>{getInitials(event.createdBy)}</span>}
      />
    </Tooltip>
  </TimelineItem>
);

const taskFlowTemplateCreated = (event, theme) => (
  <TimelineItem theme={theme}>
    <Box>
      Task Flow Template Created
      <Bold theme={theme}>{event.description}</Bold>
    </Box>
    <Tooltip title={event.createdBy} placement="top">
      <BigInitials
        theme={theme}
        children={<span>{getInitials(event.createdBy)}</span>}
      />
    </Tooltip>
  </TimelineItem>
);

const assignTemplate = (event) => (
  <div className="header-content-container">
    <div>
      <span className="bold">{`${event.createdBy} `}</span>Assigned to
      <span className="bold">{` ${event.createdFor} `}</span>on
      {` ${event.createdDate}`}
    </div>
  </div>
);

const milestoneCompleteTemplate = (event, theme) => (
  <TimelineContent
    sx={{ fontWeight: '800', color: theme.themeColor.bodyMain }}
  >
    {
      event.description
        ? `${event.description}`
        : `Milestone Closed`
    }
  </TimelineContent>
);

const milestoneOpenedTemplate = (event, theme) => (
  <TimelineContent
    sx={{ fontWeight: '800', color: theme.themeColor.bodyMain }}
  >
    {
      event.description
        ? `${event.description}`
        : `Milestone Opened`
    }
  </TimelineContent>
);

// TODO: NEED TO FIND OUT IF THIS NEEDS TO BE STYLED DIFFERENTLY
const foreclosureClosedTemplate = (event, theme) => (
  <TimelineContent
    sx={{ fontWeight: '800', color: theme.themeColor.bodyMain }}
  >
    {` Process Completed on ${event.createdDate}`}
  </TimelineContent>
);

const processChangedTemplate = (event, theme) => {
  return (
    <TimelineContent
      sx={{
        fontWeight: "800",
        display: "flex",
        background: "inherit !important",
        color: theme.themeColor.bodyMain,
        position: 'relative',
        padding: '5px 25%',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: 0,
          right: 0,
          height: '0',
          borderTop: `1px dashed ${theme.themeColor.bodySecondary}`,
        },
      }}
    >
      <span
        style={{
          padding: "0 5px",
          backgroundColor: theme.themeColor.backgroundBody,
        }}
      >
        {event.description}
      </span>
    </TimelineContent>
  );
}

const processClosedTemplate = (event, theme) => {
  return (
    <TimelineContent
      sx={{
        fontWeight: "800",
        display: "flex",
        background: "inherit !important",
        color: theme.themeColor.bodyMain,
        position: 'relative',
        padding: '5px 20%',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: 0,
          right: 0,
          height: '0',
          borderTop: `1px dashed ${theme.themeColor.bodySecondary}`,
        },
      }}
    >
      <span
        style={{
          padding: "0 5px",
          backgroundColor: theme.themeColor.backgroundBody,
        }}
      >
        {event.description}
      </span>
    </TimelineContent>
  );
}

const taskCommentTemplate = (event, theme) => {
  return (
    <TimelineItem theme={theme}>
      <Box>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ChatBubble
            sx={{
              color: theme.themeColor.bodyTetriary,
              fontSize: "16px",
              mr: 1
            }}
          />
          <ChatDot />
          <CommentTitle theme={theme}>{event.title}</CommentTitle>
        </div>
        <CommentText theme={theme}>
          <MentionHighlighter>{event.description}</MentionHighlighter>
        </CommentText>
      </Box>
      <Tooltip title={event.createdBy} placement="top">
        <SmallInitials
          theme={theme}
          children={<span>{getInitials(event.createdBy)}</span>}
        />
      </Tooltip>
    </TimelineItem>
  );
}

const holdCommentTemplate = (event, theme) => (
  <TimelineItem theme={theme}>
    <Box>
      <div style={{ display: "flex", alignItems: "center" }}>
        <ChatBubble
          sx={{
            color: theme.themeColor.bodyTetriary,
            fontSize: "16px",
            mr: 1,
          }}
        />
        <ChatDot />
        <CommentTitle theme={theme}>{event.title}</CommentTitle>
      </div>
      <CommentText theme={theme}>
        <MentionHighlighter>{event.description}</MentionHighlighter>
      </CommentText>
    </Box>
    <Tooltip title={event.createdBy} placement="top">
      <SmallInitials
        theme={theme}
        children={<span>{getInitials(event.createdBy)}</span>}
      />
    </Tooltip>
  </TimelineItem>
);

const documentUploadTemplate = (event, theme) => {
  return (
    <TimelineItem theme={theme} sx={{ minHeight: "60px" }}>
      <Box>
        {event.title}
        <Bold theme={theme}>{event.description}</Bold>
      </Box>
      <Tooltip title={event.createdBy} placement="top">
        <SmallInitials
          style={{ alignSelf: 'flex-start' }}
          theme={theme}
          children={<span>{getInitials(event.createdBy)}</span>}
        />
      </Tooltip>
    </TimelineItem>
  )
}

const documentDeletedTemplate = (event, theme) => {
  return (
    <TimelineItem theme={theme} sx={{ minHeight: "60px" }}>
      <Box>
        {event.title}
        <Bold theme={theme}>{event.description}</Bold>
      </Box>
      <Tooltip title={event.createdBy} placement="top">
        <SmallInitials
          style={{alignSelf: 'flex-start'}}
          theme={theme}
          children={<span>{getInitials(event.createdBy)}</span>}
        />
      </Tooltip>
    </TimelineItem>
  )
}

const documentUpdatedTemplate = (event, theme) => {
  return (
    <TimelineItem theme={theme} sx={{ minHeight: "60px" }}>
      <Box>
        {event.title}
        <Bold theme={theme}>{event.description}</Bold>
      </Box>
      <Tooltip title={event.createdBy} placement="top">
        <SmallInitials
          style={{alignSelf: 'flex-start'}}
          theme={theme}
          children={<span>{getInitials(event.createdBy)}</span>}
        />
      </Tooltip>
    </TimelineItem>
  )
}

const formUpdateTemplate = (event, theme) => {
  return (
    <TimelineItem theme={theme} sx={{ minHeight: "60px" }}>
      <Box>
        {event.title}
        <Bold theme={theme}>{event.description}</Bold>
      </Box>
      <Tooltip title={event.createdBy} placement="top">
        <SmallInitials
          style={{ alignSelf: 'flex-start' }}
          theme={theme}
          children={<span>{getInitials(event.createdBy)}</span>}
        />
      </Tooltip>
    </TimelineItem>
  )
}

const tldrUpdate = (
  event,
  theme,
) => {
  return (
    <TimelineItem theme={theme}>
      {event.description &&
        <Box>
          TLDR Updated
          <Bold theme={theme}>{event.description}</Bold>
        </Box>
      }
      {!event.description &&
        <Box>
          TLDR Removed
        </Box>
      }
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Tooltip title={event.createdBy} placement="top">
          <BigInitials
            theme={theme}
            children={<span>{getInitials(event.createdBy)}</span>}
          />
        </Tooltip>
      </Box>
    </TimelineItem>
  );
};

export default function generateEventsCardType(
  type,
  event,
  theme,
  handleOpenTaskComments,
  handleOpenHoldComments,
) {
  switch (type) {
  case "comment":
    return commentTemplate(event, theme);
  case "hold-created":
    return holdCreatedTemplate(event, theme, handleOpenHoldComments);
  case "hold-reopened":
    return holdReopenedTemplate(event, theme);
  case "hold-closed":
    return holdClosedTemplate(event, theme);
  case "hold-duedate-update":
    return holdDueDateTemplate(event, theme);
  case "task-created":
    return taskCreatedTemplate(
      event,
      theme,
      handleOpenTaskComments,
    );
  case "task-reopened":
    return taskReopenedTemplate(event, theme);
  case "task-reassigned":
    return taskReassignedTemplate(event, theme);
  case "task-complete":
    return taskCompleteTemplate(event, theme);
  case "task-duedate-update":
    return taskDueDateTemplate(event, theme);
  case "task-milestone-update":
    return taskMilestoneTemplate(event, theme);
  case "flow-template-created":
    return taskFlowTemplateCreated(event, theme);
  case "assign":
    return assignTemplate(event);
  case "milestone-opened":
    return milestoneOpenedTemplate(event, theme);
  case "milestone-closed":
    return milestoneCompleteTemplate(event, theme);
  case "foreclosure-closed":
    return foreclosureClosedTemplate(event, theme);
  case "process-changed":
    return processChangedTemplate(event, theme);
  case "process-closed":
    return processClosedTemplate(event, theme);
  case "task-comment":
    return taskCommentTemplate(event, theme);
  case "hold-comment":
    return holdCommentTemplate(event, theme);
  case "document-upload":
    return documentUploadTemplate(event, theme);
  case "document-deleted":
    return documentDeletedTemplate(event, theme);
  case "document-updated":
    return documentUpdatedTemplate(event, theme);
  case "form-update":
    return formUpdateTemplate(event, theme);
  case "tldr-update":
    return tldrUpdate(event, theme);
  default:
    return <div>No Type Provided</div>;
  }
}
