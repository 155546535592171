import { gql } from "@apollo/client";

export const GET_LAW_FIRM_MANAGER = gql`
  query GetLawFirmManager($processID: ID) {
    findLawFirmManager(processID: $processID) {
      id
      firstName
      lastName
      email
      userType
    }
  }
`;