import React from 'react';
import Modal from "../../styled/Modal/Modal";
import Typography from '../../styled/Typography/Typography';
import MembersList from '../MembersList/MembersList';
import Button from '../../styled/Button/Button';


const ManageMembersModal = ({ showModal, data, selectedTeamID, selectedTeam, selectedTeamMembers, setSelectedTeamMembers, setShowModal }) => {
  return (

    <Modal open={showModal} width="90%" sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h4" component="h2">{data?.teams?.find(t => t.id == selectedTeamID)?.name}</Typography>
      <MembersList teamID={selectedTeamID} selectedTeam={selectedTeam} selectedTeamMembers={selectedTeamMembers} setSelectedTeamMembers={setSelectedTeamMembers} />
      <Button
        onClick={() => {
          setShowModal(false);
        }}
        sx={{
          width: 'auto',
          border: 'none',
          boxShadow: 'none',
          marginLeft: '15px',
        }}
      >
          Close
      </Button>
    </Modal>
  )}

export default ManageMembersModal;